(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-draw/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-draw/assets/javascripts/render-component.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const isServer = typeof exports === 'object';
const trinidad = isServer ? require('trinidad-core').core : svs.core;
const getLogger = isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('banner_layouts:griditem-draw');
const mockableJackpots = isServer ? trinidad.components.require('content', 'shared').jackpots : svs.content.shared;
const funds = isServer ? trinidad.components.require('lb-utils', 'funds').api : svs.components.lbUtils.funds;
const formatUtils = isServer ? trinidad.components.require('utils', 'format').api : svs.utils.format;
const {
  getFormattedDate,
  getFormattedTime
} = isServer ? trinidad.components.require('lb-utils', 'helper-formatdate').api : svs.components.lbUtils.helpers.formatDate;
const {
  resolveProductId,
  getDrawName,
  helpers
} = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  getProductBrand
} = isServer ? require('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6') : svs.components.lbUtils.brandMapping.fn;
const {
  TTLCacheAsync
} = isServer ? require('../../../../lb-utils/ttl-cache/controller.js') : svs.components.lbUtils.ttlCache;
const ONE_MINUTE = 60 * 1000;
const BANNER_TYPES = {
  DRAW_FUNDS: 'drawFunds',
  DRAW_EVENTS: 'drawEvents'
};
const TIMEOUT_FOR_JUPITER_CALLS = 3000;
async function getDraw(_ref) {
  var _result$;
  let {
    specificDrawNumber: drawNumber,
    productId,
    specificSport,
    req
  } = _ref;
  let drawName;
  const andFilter = [];
  if (helpers.isTopptipset(productId)) {
    drawName = 'draw_topptipset,draw_topptipsetextra,draw_topptipsetstryk';
  } else {
    drawName = "draw_".concat(getDrawName(productId));
  }
  if (drawNumber) {
    andFilter.push("payload.draw.drawState;Open,payload.draw.drawNumber;".concat(drawNumber));
  } else {
    andFilter.push('payload.draw.drawState;Open');
  }
  if (specificSport) {
    andFilter.push("payload.draw.sport.type;".concat(specificSport));
  }
  const includes = ['payload.draw.productId', 'payload.draw.drawNumber', 'payload.draw.regCloseTime'];
  const path = "/search/1/query?ctx=".concat(drawName, "&includes=").concat(includes.join(','), "&andfilter=").concat(andFilter.join(','), "&count=1&sort=payload.draw.regCloseTime;asc");
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error,
    result
  } = isServer ? response : response.response;
  const draw = (_result$ = result[0]) === null || _result$ === void 0 || (_result$ = _result$.payload) === null || _result$ === void 0 ? void 0 : _result$.draw;
  if (error) {
    logger.info("API-error while listing current draws (product ".concat(drawName, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  if (!Array.isArray(result) || result.length === 0) {
    logger.info("Missing \"draws\" in response from draw-API (product ".concat(drawName, ")"));
    return false;
  }
  return draw;
}

async function getDrawEvents(_ref2) {
  var _result$2;
  let {
    payload
  } = _ref2;
  const andFilter = [];
  let drawName;
  const {
    product,
    specificSport,
    specificDrawNumber: drawNumber,
    req
  } = payload;
  const productId = resolveProductId(product);
  if (drawNumber) {
    andFilter.push("payload.draw.drawState;Open,payload.draw.drawNumber;".concat(drawNumber));
  } else {
    andFilter.push('payload.draw.drawState;Open');
  }
  if (specificSport) {
    andFilter.push("payload.draw.sport.type;".concat(specificSport));
  }
  if (helpers.isTopptipset(productId)) {
    drawName = 'draw_topptipset,draw_topptipsetextra,draw_topptipsetstryk';
  } else {
    drawName = "draw_".concat(getDrawName(productId));
  }
  const includes = ['payload.draw.drawEvents.match.participants.mediumName', 'payload.draw.events.match.participants.mediumName', 'payload.draw.regCloseTime'];
  const path = "/search/1/query?ctx=".concat(drawName, "&includes=").concat(includes.join(','), "&andfilter=").concat(andFilter.join(','), "&count=1&sort=payload.draw.regCloseTime;asc");
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error,
    result
  } = isServer ? response : response.response;
  if (error) {
    logger.info("API-error while listing current draws (product ".concat(drawName, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  if (!Array.isArray(result) || result.length === 0) {
    logger.info("Missing \"draws\" in response from draw-API (product ".concat(drawName, ")"));
    return {};
  }
  const draw = (_result$2 = result[0]) === null || _result$2 === void 0 || (_result$2 = _result$2.payload) === null || _result$2 === void 0 ? void 0 : _result$2.draw;
  if (draw) {
    const events = draw.drawEvents || draw.events;
    const slicedEvents = (events === null || events === void 0 ? void 0 : events.slice(0, 3)) || [];
    draw.eventDescriptions = slicedEvents.map(event => {
      var _event$match, _event$match2;
      const team1 = (event === null || event === void 0 || (_event$match = event.match) === null || _event$match === void 0 || (_event$match = _event$match.participants[0]) === null || _event$match === void 0 ? void 0 : _event$match.mediumName) || '';
      const team2 = (event === null || event === void 0 || (_event$match2 = event.match) === null || _event$match2 === void 0 || (_event$match2 = _event$match2.participants[1]) === null || _event$match2 === void 0 ? void 0 : _event$match2.mediumName) || '';
      return "".concat(team1, " - ").concat(team2);
    });
  }
  return {
    draw,
    productId
  };
}
async function getDrawCloseTime(_ref3) {
  var _result$3;
  let {
    drawNumber,
    productId,
    req
  } = _ref3;
  let andFilter;
  let drawName;
  if (drawNumber) {
    andFilter = "payload.draw.drawState;Open,payload.draw.drawNumber;".concat(drawNumber);
  } else {
    andFilter = 'payload.draw.drawState;Open';
  }
  if (helpers.isTopptipset(productId)) {
    drawName = 'draw_topptipset,draw_topptipsetextra,draw_topptipsetstryk';
  } else {
    drawName = "draw_".concat(getDrawName(productId));
  }
  const path = "/search/1/query?ctx=".concat(drawName, "&includes=payload.draw.regCloseTime&andfilter=").concat(andFilter, "&count=1&sort=payload.draw.regCloseTime;asc");
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error,
    result
  } = isServer ? response : response.response;
  if (error) {
    logger.info("API-error while listing current draws (product ".concat(drawName, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  if (!Array.isArray(result) || result.length === 0) {
    logger.info("Missing \"draws\" in response from draw-API (product ".concat(drawName, ")"));
    return {};
  }
  const regCloseTime = (_result$3 = result[0]) === null || _result$3 === void 0 || (_result$3 = _result$3.payload) === null || _result$3 === void 0 || (_result$3 = _result$3.draw) === null || _result$3 === void 0 ? void 0 : _result$3.regCloseTime;
  return regCloseTime;
}

async function getDrawFunds(_ref4) {
  var _rollOverAndExtraMone;
  let {
    payload
  } = _ref4;
  const {
    product,
    specificSport,
    specificDrawNumber,
    req
  } = payload;
  let jackpotAmountString;
  let currentFunds;
  let rollOverAndExtraMoney;
  let jackpots;
  let fundsDrawNumber;
  let currentDrawNumber = specificDrawNumber;
  const productId = resolveProductId(product);
  const currentDraw = await getDraw({
    specificDrawNumber,
    productId,
    specificSport,
    req
  });
  if (!currentDraw) {
    return {};
  }
  if (specificSport || specificDrawNumber) {
    currentDrawNumber = currentDraw.drawNumber;
  }
  try {
    jackpots = isServer ? await mockableJackpots.getJackpots({
      req,
      product: productId
    }) : await mockableJackpots.getJackpots([productId]);
  } catch (error) {
    logger.info('Error when fetching jackpots', error, req);
    return {};
  }
  if (Array.isArray(jackpots) && jackpots.length > 0) {
    const currentJackpot = currentDrawNumber ? jackpots.find(jackpot => parseInt(jackpot.drawNumber, 10) === parseInt(currentDrawNumber, 10)) : jackpots[0];
    jackpotAmountString = currentJackpot !== null && currentJackpot !== void 0 && currentJackpot.defaultJackpot ? null : currentJackpot === null || currentJackpot === void 0 ? void 0 : currentJackpot.jackpotAmountString;
    fundsDrawNumber = currentJackpot === null || currentJackpot === void 0 ? void 0 : currentJackpot.drawNumber;
  }
  if (!jackpotAmountString) {
    try {
      if (helpers.isTopptipset(productId)) {
        const products = ['topptipset', 'topptipsetstryk', 'topptipsetextra'];
        currentFunds = await funds.getFunds({
          req,
          products
        });
      } else {
        currentFunds = await funds.getFunds({
          req,
          product
        });
      }
    } catch (error) {
      logger.info('Error when fetching funds', error, req);
      return {};
    }
    if (Array.isArray(currentFunds) && currentFunds.length > 0) {
      const currentFund = currentDrawNumber ? currentFunds.find(fund => fund.drawNumber === parseInt(currentDrawNumber, 10)) : currentFunds[0];
      if (currentFund) {
        rollOverAndExtraMoney = currentFund.type === funds.fundsConstants.FUND_TYPE_ROLLOVER ? {
          type: currentFund.type,
          text: 'Rullpott!',
          fundsAmount: currentFund.fundsAmount,
          closeTime: currentFund.closeTime
        } : {
          type: currentFund.type,
          text: "".concat(formatUtils.Currency(currentFund.fundsAmount, false), " kr extra!"),
          fundsAmount: currentFund.fundsAmount,
          closeTime: currentFund.closeTime
        };
        fundsDrawNumber = currentFund === null || currentFund === void 0 ? void 0 : currentFund.drawNumber;
      }
    }
  }
  let closeTime = ((_rollOverAndExtraMone = rollOverAndExtraMoney) === null || _rollOverAndExtraMone === void 0 ? void 0 : _rollOverAndExtraMone.closeTime) || (currentDraw === null || currentDraw === void 0 ? void 0 : currentDraw.regCloseTime);

  if (!currentDrawNumber && fundsDrawNumber) {
    closeTime = await getDrawCloseTime({
      drawNumber: fundsDrawNumber,
      productId,
      req
    });
  }
  return {
    jackpotAmountString,
    rollOverAndExtraMoney,
    productId,
    closeTime
  };
}
function getDrawFundsSetup(_ref5) {
  let {
    jackpotAmountString,
    rollOverAndExtraMoney,
    closeTime
  } = _ref5;
  let logoText;
  let title;
  let typeOfSpeechBubble;
  let speechBubbleRotation;
  if (jackpotAmountString) {
    logoText = 'Jackpot!';
    title = jackpotAmountString;
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_1';
    speechBubbleRotation = 'positive';
  } else if ((rollOverAndExtraMoney === null || rollOverAndExtraMoney === void 0 ? void 0 : rollOverAndExtraMoney.type) === funds.fundsConstants.FUND_TYPE_ROLLOVER) {
    logoText = rollOverAndExtraMoney.text;
    title = "".concat(formatUtils.Currency(rollOverAndExtraMoney.fundsAmount), " kr");
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_2';
    speechBubbleRotation = 'negative';
  } else if ((rollOverAndExtraMoney === null || rollOverAndExtraMoney === void 0 ? void 0 : rollOverAndExtraMoney.type) === funds.fundsConstants.FUND_TYPE_ADJUST) {
    logoText = rollOverAndExtraMoney.text;
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_3';
    speechBubbleRotation = 'negative';
  }
  const formattedDate = closeTime ? getFormattedDate(closeTime, true) : null;
  const formattedTime = closeTime ? getFormattedTime(closeTime) : null;
  const subtitle = formattedDate && formattedTime ? "Spelstopp ".concat(formattedDate, " ").concat(formattedTime) : null;
  return {
    logoText,
    title,
    subtitle,
    typeOfSpeechBubble,
    speechBubbleRotation
  };
}
function getDrawEventsSetup(_ref6) {
  let {
    draw
  } = _ref6;
  const {
    regCloseTime: closeTime
  } = draw;
  const formattedDate = closeTime ? getFormattedDate(closeTime, true) : null;
  const formattedTime = closeTime ? getFormattedTime(closeTime) : null;
  const subtitle = formattedDate && formattedTime ? "Spelstopp ".concat(formattedDate, " ").concat(formattedTime) : null;
  return {
    subtitle
  };
}
const Global = Object.seal({
  fundsCache: new TTLCacheAsync({
    onRefreshAsync: getDrawFunds,
    ttl: ONE_MINUTE,
    onError: error => {
      logger.info("fundsCache.readAsync() rejected (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    },
    timeoutMS: TIMEOUT_FOR_JUPITER_CALLS
  }),
  eventsCache: new TTLCacheAsync({
    onRefreshAsync: getDrawEvents,
    ttl: ONE_MINUTE,
    onError: error => {
      logger.info("eventsCache.readAsync() rejected (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    },
    timeoutMS: TIMEOUT_FOR_JUPITER_CALLS
  }),
  getDrawCache: typeOfBanner => typeOfBanner === BANNER_TYPES.DRAW_FUNDS ? Global.fundsCache : Global.eventsCache
});

async function renderComponent(bannerItem, widgetInfo, position, req, res) {
  var _svs;
  bannerItem.layoutConfig = bannerItem.layoutConfig || {};
  const {
    product,
    specificDrawNumber,
    specificSport,
    bannerType,
    bannerText,
    title,
    caption
  } = bannerItem.layoutConfig;
  const hbsCompiled = isServer ? global.internalInstances.get('hbs').cache['components-banner_layouts-griditem_draw-item'] :
  svs.banner_layouts.griditem_draw.templates.item;
  const isAdmin = isServer ?
  req.userSession.hasRole(req.userSession.roles.INTERNAL) :
  svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
  const trackingStringWithFixedStructure = [widgetInfo.widgetType, widgetInfo.trackingId || widgetInfo.widgetName, position, bannerItem.layoutType, bannerItem.title].join('/');
  const cacheKey = widgetInfo.unitTestId ? "".concat(product, "_").concat(bannerType, "_").concat(specificDrawNumber || 'auto', "_").concat(widgetInfo.unitTestId, "_").concat(specificSport || 'all') : "$".concat(product, "_").concat(bannerType, "_").concat(specificDrawNumber || 'auto', "_").concat(specificSport || 'all');
  const cachedData = await Global.getDrawCache(bannerType).readAsync({
    key: cacheKey,
    payload: {
      specificDrawNumber,
      specificSport,
      product,
      req
    }
  });
  const productId = cachedData === null || cachedData === void 0 ? void 0 : cachedData.productId;
  const jackpotAmountString = cachedData === null || cachedData === void 0 ? void 0 : cachedData.jackpotAmountString;
  const rollOverAndExtraMoney = cachedData === null || cachedData === void 0 ? void 0 : cachedData.rollOverAndExtraMoney;
  const draw = cachedData === null || cachedData === void 0 ? void 0 : cachedData.draw;
  const closeTime = cachedData === null || cachedData === void 0 ? void 0 : cachedData.closeTime;
  if (!productId && !draw) {
    return '';
  }
  const {
    logoText,
    title: updatedTitle,
    subtitle,
    typeOfSpeechBubble,
    speechBubbleRotation
  } = bannerType === BANNER_TYPES.DRAW_FUNDS ? getDrawFundsSetup({
    jackpotAmountString,
    rollOverAndExtraMoney,
    closeTime
  }) : getDrawEventsSetup({
    draw
  });
  const displayBannerText = !typeOfSpeechBubble && typeof bannerText === 'string' && bannerText.trim() !== '';
  const productBrand = getProductBrand(productId);

  const isMobile = isServer ? req.capabilities.formFactor === 'mobile' : svs.core.detect.formfactor.mobile();
  return hbsCompiled(_objectSpread(_objectSpread({}, bannerItem.layoutConfig), {}, {
    isAdmin,
    bannerId: bannerItem.id,
    bannerType,
    features: isServer ? res.locals.features : (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features,
    displayBannerText,
    linkActionConfig: bannerItem.callToActions[0].actionConfig,
    branding: productBrand,
    landscapeImage: bannerItem.images.find(image => image.typeName === 'productImage'),
    portraitImage: bannerItem.images.find(image => image.typeName === 'productImageOptional'),
    trackingString: trackingStringWithFixedStructure,
    logoText,
    typeOfSpeechBubble,
    speechBubbleRotation,
    ribbonSize: isMobile ? 200 : 'responsive',
    jackpotAmountString,
    title: updatedTitle || title,
    subtitle: caption || subtitle,
    draw
  })) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_layouts.griditem_draw', {
    renderComponent
  });
}

 })(window);